@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");

$main-color: #c8a877;

* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  letter-spacing: -0.02em;
  padding: 0;
  margin: 0;
}

p {
  line-height: 130%;
}

::selection {
  background: $main-color;
  color: #fff;
}

.container {
  max-width: 80em;
}

$screen-md-min: 54.8em;
$screen-lg-min: 80em;

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}
