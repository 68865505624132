@import "../styles/";

.main-footer {
  iframe {
    border: none;
    height: 450px;
    width: 100%;
  }
  .copyright {
    background-color: #000;
    color: #fff;
    font-size: 14px;
    font-weight: lighter;
    section {
      padding: 20px;
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      justify-content: space-between;
      @include md {
        flex-direction: row;
      }
    }
    div {
      font-size: 0.8rem;
      p {
        display: flex;
        align-items: center;
        margin: 15px 0;
        svg {
          fill: $main-color;
          margin-right: 10px;
          font-size: 1.4rem;
        }
      }
    }
    .footer-contact {
      display: none;
      @include md {
        display: initial;
      }
    }
  }
}
