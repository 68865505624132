@import "../styles/";

.main-hero {
  background: url("../images/main-hero-mobile.png") no-repeat center center;
  background-size: cover;
  height: 280px;
  @include md {
    background: url("../images/main-hero.png") no-repeat center center;
    height: 520px;
  }
}

.hero-text {
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: -45px;
  h1 {
    color: $main-color;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: lighter;
  }
  p {
    font-size: 0.8rem;
    text-transform: uppercase;
  }
  @include md {
    flex-direction: row;
    margin-top: -60px;
  }
  @include lg {
    h1 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1rem;
    }
  }
  .text-wrap {
    background-color: #fff;
    border: 1px solid $main-color;
    align-items: center;
    display: flex;
    height: 90px;
    margin: 0 auto;
    margin-top: -1px;
    width: 85%;
    .text-box {
      margin: 0 auto;
      text-align: center;
    }
    @include md {
      margin-left: -1px;
      height: 120px;
    }
  }
}

.text {
  margin: 0 auto;
  padding: 60px 40px;
  text-align: center;
  width: 85%;
  @include lg {
    font-size: 1.2rem;
    width: 60%;
  }
}

.main-areas {
  background: url("../images/main-areas.png") no-repeat fixed center;
  background-size: cover;
  text-align: center;
  color: #fff;
  padding: 80px;
  .container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    @include md {
      justify-content: space-evenly;
      flex-direction: row;
    }
    .area {
      padding: 0 10px;
      width: 100%;
      margin-bottom: 60px;
      @include md {
        padding: 0 20px;
        width: 30%;
      }
      svg {
        font-size: 4rem;
        color: $main-color;
        margin: 20px;
      }
      h2 {
        font-size: 1.1rem;
        font-weight: 600;
      }
      p {
        margin-top: 10px;
      }
    }
  }
  button {
    background: none;
    border: 1px solid $main-color;
    cursor: pointer;
    font-size: 16px;
    color: $main-color;
    margin-top: 20px;
    padding: 20px;
    position: relative;
    transition: all 0.3s;
    overflow: hidden;
    z-index: 1;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: $main-color;
      transition: all 0.3s;
      z-index: -1;
    }
    &:hover {
      color: #fff;
      &:before {
        width: 100%;
      }
    }
  }
}

.main-press {
  padding: 80px 40px;
  margin: 0 auto;
  button {
    background: none;
    border: 1px solid $main-color;
    cursor: pointer;
    font-size: 16px;
    color: $main-color;
    padding: 20px;
    position: relative;
    transition: all 0.3s;
    overflow: hidden;
    z-index: 1;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: $main-color;
      transition: all 0.3s;
      z-index: -1;
    }
    &:hover {
      border-color: $main-color;
      color: #fff;
      &:before {
        width: 100%;
      }
    }
  }
  div {
    margin: 0 auto;
    .articles {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      article {
        margin-bottom: 80px;
        width: 100%;
        img {
          height: 300px;
          object-fit: none;
          margin-bottom: 10px;
          width: 100%;
        }
        h2 {
          color: $main-color;
          font-size: 1.4rem;
          font-weight: 600;
          margin-bottom: 20px;
        }
        @include md {
          margin-bottom: 20px;
        }
      }
    }
    @include md {
      justify-content: space-between;
      .articles {
        flex-direction: row;
        article {
          width: 31%;
          h2 {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

.main-contact {
  padding: 80px 20px;
  background: url("../images/main-contact.png") no-repeat fixed center;
  background-size: cover;
  color: #fff;
  text-align: center;
  div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    p {
      font-size: 1.1rem;
      margin-bottom: 40px;
    }
    @include md {
      align-items: baseline;
      flex-direction: row;
      p {
        margin-bottom: 0;
        margin-right: 40px;
      }
    }
  }
  button {
    background: none;
    border: 1px solid #fff;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    position: relative;
    transition: all 0.3s;
    overflow: hidden;
    z-index: 1;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: #fff;
      transition: all 0.3s;
      z-index: -1;
    }
    &:hover {
      border-color: #fff;
      color: $main-color;
      &:before {
        width: 100%;
      }
    }
  }
}

.page-title {
  background: url("../images/main-areas.png") no-repeat fixed center;
  background-size: cover;
  color: $main-color;
  padding: 100px 20px 40px 20px;
  h1 {
    font-size: 1.8rem;
    font-weight: lighter;
    max-width: 1120px;
    margin: 0 auto;
    text-transform: uppercase;
    @include md {
      font-size: 2rem;
    }
  }
}

.about {
  .quote {
    font-size: 1.4em;
    text-align: center;
  }
  .container {
    padding: 40px 40px 0 40px;
    margin: 0 auto;
  }
  .office {
    margin-bottom: 60px;
    .about-image {
      background: url("../images/about-office-m.png") no-repeat center center;
      background-size: 100% auto;
      background-position: cover;
      width: 100%;
      height: 240px;
      margin-bottom: 40px;
      @include md {
        background: url("../images/about-office.png") no-repeat center center;
        height: 320px;
        margin-bottom: 0;
      }
    }
    @include md {
      .about-image {
        margin-bottom: 0;
      }
      .flex {
        align-items: center;
        display: flex;
      }
    }
  }
  h1 {
    border-bottom: 1px solid $main-color;
    color: $main-color;
    font-size: 2rem;
    font-weight: lighter;
    padding-bottom: 20px;
    text-transform: uppercase;
    margin-bottom: 40px;
  }
  p {
    @include md {
      margin-left: 40px;
    }
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .gallery {
    margin-top: 40px;
    img {
      object-fit: cover;
    }
  }
}

.areas {
  background: url("../images/about-areas.png") no-repeat fixed center;
  background-size: cover;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    list-style: none;
    li {
      background-color: rgba(255, 255, 255, 0.6);
      align-items: center;
      display: flex;
      text-align: center;
      width: 100%;
      padding: 20px 30px;
      margin-bottom: 40px;
      @include md {
        width: 30%;
      }
      svg {
        font-size: 3rem;
        color: $main-color;
        margin-right: 30px;
        height: 100%;
      }
      h2 {
        display: flex;
        align-items: center;
        height: 100%;
        text-align: left;
        color: #000;
        font-size: 1.1rem;
        font-weight: 600;
      }
      p {
        margin: 0 auto;
      }
    }
  }
}

.members {
  padding: 40px 0;
  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    list-style: none;
    @include md {
      flex-direction: row;
    }
    li {
      text-align: center;
      background-color: rgb(247, 247, 247);
      box-sizing: border-box;
      width: 100%;
      padding: 0 40px 40px 40px;
      margin-top: 150px;
      margin-bottom: 40px;
      position: relative;
      @include md {
        width: 48%;
        margin-top: 180px;
      }
      .avatar {
        padding: 15px;
        background-color: #fff;
        border: 1px solid $main-color;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin-top: -130px;
        @include md {
          width: 300px;
          height: 300px;
          margin-top: -160px;
        }
      }
      .quote-icon {
        position: absolute;
        top: 5%;
        right: 5%;
        width: 30px;
        height: auto;
      }
      button {
        background: none;
        border: none;
        cursor: pointer;
        color: $main-color;
        transition: 0.3s ease opacity;
      }
      button:hover {
        opacity: 0.7;
      }
    }
    h2 {
      color: $main-color;
      margin-top: 30px;
      margin-bottom: 10px;
    }
    h3 {
      margin-bottom: 20px;
    }
    p {
      margin: 0 auto;
    }
  }
}

.modal {
  width: 80%;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding: 40px;
  display: flex;
  flex-direction: column;
  @include md {
    padding: 60px;
    width: auto;
  }
}

.modal .modal-body {
  overflow-y: scroll;
  max-height: 600px;
  height: 100%;
  place-content: center;
  p {
    margin-right: 30px;
  }
}

.press {
  .container {
    margin: 0 auto;
    padding: 80px 20px;
  }
  .articles {
    article {
      position: relative;
      margin-bottom: 120px;
      padding: 0 20px;
      img {
        height: 420px;
        object-fit: none;
        width: 100%;
        margin-bottom: 40px;
      }
      .post-text {
        position: relative;
        margin: 0 auto;
        width: 100%;
        @include md {
          width: 70%;
        }
      }
      h2 {
        color: $main-color;
        font-size: 2rem;
        margin-bottom: 40px;
      }
      p {
        margin-bottom: 20px;
        a,
        a:visited {
          color: $main-color;
        }
        a:hover,
        a:active {
          text-decoration: none;
        }
      }
    }
  }
}

.contact {
  .container {
    padding: 80px 40px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .contact-img {
      background: url("../images/contact.png") no-repeat center center;
      background-size: auto 100%;
      background-position: cover;
      width: 100%;
      height: 240px;
      margin-bottom: 40px;
      @include md {
        height: auto;
        margin-bottom: 0;
        width: 40%;
      }
    }
    .contact-text {
      p {
        margin-bottom: 20px;
      }
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      .render-alert {
        p {
          display: flex;
          align-items: center;
          height: 100%;
          svg {
            fill: green;
            margin-right: 10px;
          }
        }
        background-color: aquamarine;
        width: 100%;
        padding: 10px 20px;
        height: 60px;
        margin-bottom: 20px;
      }
      form {
        input {
          color: #000;
          background-color: rgb(247, 247, 247);
          border: none;
          width: 100%;
          height: 60px;
          margin-bottom: 20px;
          padding: 10px 20px;
        }
        textarea {
          background-color: rgb(247, 247, 247);
          border: none;
          height: 360px;
          padding: 10px 20px;
          width: 100%;
          resize: none;
        }
        input:focus,
        textarea:focus {
          color: #000;
          background-color: rgb(240, 240, 240);
          outline: none;
        }
        button {
          background: none;
          border: 1px solid $main-color;
          cursor: pointer;
          font-size: 16px;
          color: $main-color;
          padding: 20px;
          position: relative;
          margin-top: 20px;
          transition: all 0.3s;
          overflow: hidden;
          width: 100%;
          z-index: 1;
          &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: $main-color;
            transition: all 0.3s;
            z-index: -1;
          }
          &:hover {
            border-color: $main-color;
            color: #fff;
            &:before {
              width: 100%;
            }
          }
        }
      }
    }
    @include md {
      flex-direction: row;
      justify-content: space-between;
      .contact-text {
        margin-left: 40px;
      }
    }
  }
}
