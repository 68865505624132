@import "../styles/";

.main-header {
  background-color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  padding: 0 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  .main-nav {
    margin: 0 auto;
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 60px;
    .logo {
      img {
        max-height: 30px;
        width: 100%;
      }
    }
    .menu {
      background-color: rgba(0, 0, 0, 0.9);
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      width: 50%;
      z-index: 200;
      transform: translateX(-100%);
      transition: 0.3s;
      ul {
        padding: 20px;
        li {
          padding: 15px;
          a,
          a:visited {
            align-items: center;
            color: #fff;
            display: flex;
            height: 100%;
            text-decoration: none;
            text-transform: uppercase;
            transition: 0.2s ease color;
          }
          a:hover,
          a:active {
            color: $main-color;
          }
        }
      }
      @include md {
        background: none;
        display: flex;
        position: static;
        transform: none;
        margin: 0;
        width: auto;
        ul {
          display: flex;
          list-style: none;
          padding: 0;
          li {
            padding: 0;
            a,
            a:visited {
              padding: 0 15px;
              align-items: center;
              display: flex;
              color: #fff;
              height: 100%;
              text-decoration: none;
              text-transform: uppercase;
              transition: 0.2s ease background-color;
            }
            a:hover,
            a:active {
              color: #fff;
              background-color: $main-color;
            }
          }
        }
      }
    }
    .social-icons {
      display: flex;
    }
    .menu-open {
      transform: translateX(0%);
    }
    .menu-toggle {
      align-items: center;
      display: flex;
      font-size: 1.2rem;
      color: #fff;
      cursor: pointer;
      height: 100%;
      @include md {
        display: none;
      }
    }
  }
}
